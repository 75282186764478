<template>
  <BaseLayout :breadcrumb="breadcrumb" :show-popup="false">
    <CenteredTitle>{{ translation('login_page-title') }}</CenteredTitle>
    <LoginForm/>
    <SignHint
        :title="translation('registration_hint-title')"
        :text="translation('registration_hint-text')"
        :link="translation('registration_hint-link')"
        :url="'/registration'"
    />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import CenteredTitle from "@/components/CenteredTitle";
import LoginForm from '@/components/LoginForm.vue'
import SignHint from "@/components/SignHint";
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  computed: {
    breadcrumb() {
      return [{
        title: this.translation('login_page-title'),
        url: '/login',
      }];
    }
  },
  mixins: [codeBooksMixin],
  components: {
    SignHint,
    CenteredTitle,
    BaseLayout,
    LoginForm,
  },
}
</script>
